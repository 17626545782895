import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/Layout"

/* 404 page - similar structure to Coming Soon */

export default () => {
  return (
    <Layout>
      <Helmet>
        <title>Meet MJN - 404</title>
        <body className="coming-soon" />
      </Helmet>
      <main role="main" className="coming-soon">
        <section className="main-item">
          <span className="main-item__title"><strong>404</strong></span>
          <span className="main-item__title -sub-title">Page could not be found</span>
          <nav className="breadcrumb -white" role="navigation">
            <Link to="/home" title="Home">
              <i className="fas fa-arrow-left"></i>
              Home
            </Link>
            <br />
            <br />
            <Link to="/zh" title="首页">
              <i className="fas fa-arrow-left"></i>
              首页
            </Link>
          </nav>
        </section>
      </main>
    </Layout>
  )
}